"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserCredentials = /** @class */ (function () {
    function UserCredentials(username, password) {
        this.username = username;
        this.password = password;
    }
    return UserCredentials;
}());
exports.default = UserCredentials;
